import React from "react"
import { Link, navigate } from "gatsby"
import { isLoggedIn, logout } from "../../services/auth"
import { ReactComponent as Logo } from '../../../static/logo/logo.svg'
import logoUrl from '../../../static/logo/johnson_logo.svg'

export default function NavBar() {
  return (
  <div className="row">
    <div className="col-sm-12">
      <nav className="navbar navbar-dark bg-transparent" style={{paddingLeft: '1rem', paddingRight: '1rem'}}>
        <Link className="navbar-brand" to="/"><Logo /></Link>
        {` `}
        <ul className="navbar-nav mr-auto mt-2">
          <li className="nav-item">
            <img className="mx-4" style={{ width: '80px' }} src={logoUrl} alt="Logo" />
            <Link className="nav-link text-white" to="/contacto">Contacto</Link>
            {` `}
            {isLoggedIn() ? (
            <a
              className="nav-link text-white"
              href="/"
              onClick={event => {
                event.preventDefault()
                logout(() => navigate(`/app/login`))
              }}
            >
               | Salir
            </a>
            ) : null}
          </li>
        </ul>
      </nav>
    </div>
  </div>
  )
}